import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    redirect: '/zhanyu'
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import("../views/login.vue"),
  },
  {
    path: "/homepage",
    name: "homepage",
    component: () =>
      import(/* webpackChunkName: "homepage" */ "../views/gateway/homepage.vue"),
  },
  {
    path: "/zyBrand",
    name: "zyBrand",
    component: () =>
      import(/* webpackChunkName: "gateway" */ "../views/gateway/zy-brand.vue"),
  },
  {
    path: "/industrialPark",
    name: "industrialPark",
    component: () =>
      import(/* webpackChunkName: "gateway" */ "../views/gateway/industrial-park.vue"),
  },
  {
    path: "/parkService",
    name: "parkService",
    component: () =>
      import(/* webpackChunkName: "gateway" */ "../views/gateway/park-service.vue"),
  },
  {
    path: "/newsCenter",
    name: "newsCenter",
    component: () =>
      import(/* webpackChunkName: "gateway" */ "../views/gateway/news-center.vue"),
  },
  {
    path: "/subPages",
    name: "subPages",
    component: () =>
      import(/* webpackChunkName: "gateway" */ "../views/gateway/sub-pages.vue"),
  },
  {
    path: "/zhanyu",
    name: "zhanyu",
    component: () =>
      import(/* webpackChunkName: "overview" */ "../views/zhanyu/index.vue"),
    redirect: '/zhanyu/overview',
    children: [
      {
        path: "overview",
        name: "overview",
        component: () =>
          import(/* webpackChunkName: "overview" */ "../views/zhanyu/children/overview.vue"),
      },
      {
        path: "breedingSeeding",
        name: "breedingSeeding",
        component: () =>
          import(/* webpackChunkName: "zhanyu" */ "../views/zhanyu/children/breeding-seeding.vue"),
      },
      {
        path: "zyuBrand",
        name: "zyuBrand",
        component: () =>
          import(/* webpackChunkName: "zhanfive" */ "../views/zhanyu/children/zyu-brand.vue"),
      },
      {
        path: "digitalPark",
        name: "digitalPark",
        component: () =>
          import(/* webpackChunkName: "zhanfour" */ "../views/zhanyu/children/digital-park.vue"),
      },
      {
        path: "logisticsTrade",
        name: "logisticsTrade",
        component: () =>
          import(/* webpackChunkName: "zhanyusec" */ "../views/zhanyu/children/logistics-trade.vue"),
      },
      {
        path: "feedProcessing",
        name: "feedProcessing",
        component: () =>
          import(/* webpackChunkName: "zhanyuthr" */ "../views/zhanyu/children/feed-processing.vue"),
      },
    ]
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
  // {
  //   path: "/onePicture",
  //   name: "onePicture",
  //   component: () =>
  //     import(/* webpackChunkName: "onePicture" */ "../views/one-picture/one-picture.vue"),
  // },
  // {
  //   path: "/baiduDemo",
  //   name: "baiduDemo",
  //   component: () =>
  //     import(/* webpackChunkName: "baiduDemo" */ "../demo/baidu-map-demo/baidu-map-demo.vue"),
  // },
  // {
  //   path: "/echartsDemo",
  //   name: "echartsDemo",
  //   component: () =>
  //     import(/* webpackChunkName: "echartsDemo" */ "../demo/echarts-demo/echarts-demo.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    document.querySelector('#app').scrollTop = 0
    return { x: 0, y: 0 };
  },
});

export default router;
