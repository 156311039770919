import Vue from "vue";
import {
  Button,
  Loading,
  Message,
  Select,
  Option
} from 'element-ui';
import App from "./App.vue";
import router from "./router";

// axios 基于 Promise 的 HTTP 请求插件
import axios from './axios/index'
Vue.prototype.$axios = axios

// 全局自定义变量、方法
import $ from './utils/methods/methods'
// 引入 moment
import moment from 'moment';
// console.log('$ in main', $)
Vue.prototype.$ = $

Vue.component(Button.name, Button);
Vue.component(Select.name, Select);
Vue.component(Option.name, Option);

Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$message = Message;

import rem from './utils/rem/rem'
Vue.use(rem)

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
