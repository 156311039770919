<template>
  <div class="home">
    <el-button type="warning" plain @click="goto">警告按钮</el-button>
    <el-button type="warning" plain @click="dd">警告按钮</el-button>
    <el-button type="warning" plain @click="$router.push('echartsDemo')"
      >echartsDemo</el-button
    >
    <img alt="Vue logo" src="../assets/logo.png" />
    <HelloWorld msg="Welcome to Your Vue.js App" />
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  data() {
    return {
      tt: 99,
    }
  },
  methods: {
    dd() {
      this.$router.push('zhanyu')
    },
    goto() {
      this.$message.error('fasdfj')
      this.$router.push('onePicture')
    },
  },
  components: {
    HelloWorld,
  },
  mounted() {},
}
</script>
