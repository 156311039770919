import axios from 'axios'
import Vue from 'vue'
import {
  Message
} from 'element-ui'

const goLogin = function () {
  // let query = window.location.hash.substr(window.location.hash.indexOf('?') + 1)
  // console.log('query', query)
  isShowMessage = false
  localStorage.clear()
  window.location.href = `${location.origin}/login/?url=${location.pathname.slice(1)}`
}

let isShowMessage = false

// 设置axios请求的默认配置（接口的参数继承默认配置）
// 正式外网： https://zhanyu.tongdun.com
// 开发内网： http://gateway.uyu.com
// axios.defaults.baseURL = ''
axios.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8'
axios.defaults.timeout = 500000 // 请求超时时间

// axios拦截器
// http request 拦截器
axios.interceptors.request.use(
  config => {
    // console.info('进入接口request拦截器')
    // console.log('config:', config)
    // 过滤不需要'登录状态'即可调用的接口
    let exceptUrlArr = []
    exceptUrlArr.push(`/zhanjiang-admin/systemUserInfo/login`)

    // console.log('rotuer', exceptUrlArr, config.url)
    // 拦截并检查其它需要'登录状态'的接口
    if (!exceptUrlArr.includes(config.url)) {
      let sessionId = localStorage.getItem('sessionId')
      if (sessionId && sessionId !== 'null') {
        // 给每个http header都加上sessionId
        // console.log('sessionId:', sessionId)
        Vue.prototype.$axios.defaults.headers['Authorization'] = sessionId
        // 替换当前请求的接口的sessionId
        config.headers['Authorization'] = sessionId
      } else if (!sessionId || sessionId === 'null') {
        !isShowMessage && Message.error({
          message: '您尚未登录，请先登录！'
        })
        isShowMessage = true
        setTimeout(() => {
          goLogin()
          return Promise.reject()
        }, 1000)
      }
    }

    return config
  },
  (err) => {
    Message.error({
      message: '请求超时...'
    })
    return Promise.reject(err)
  }
)

// http response 拦截器
axios.interceptors.response.use(
  (res) => {
    // console.info('进入接口response拦截器')

    if (res.data.code === '00000') {
      // console.log('回调正常')
      return res.data
    } else {
      console.info('res', res)
      console.log(`接口${res.config.url}，返回的code为${res.data.code}`)

      switch (res.data.code.toString()) {
        case 'A0230':
          // 后端判断sessionId失效

          Message.error({
            message: '您的登录信息已过期，请重新登录！'
          })
          setTimeout(() => {
            goLogin()
          }, 1000)
          break
        case 'A0230':
          // 后端判断ticket失效

          Message.error({
            message: '您的登录信息已过期，请重新登录！'
          })
          setTimeout(() => {
            goLogin()
          }, 1000)
          break
        default:
          Message.error({
            message: res.data.msg
          })
      }

      return Promise.reject(new Error(res.data.msg || 'Error'))
    }
  },
  (err) => {
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          err.message = '请求错误'
          break

        case 401:
          err.message = '未授权，请登录'
          setTimeout(() => {
            goLogin()
          }, 1000)
          break

        case 403:
          err.message = '拒绝访问'
          break

        case 404:
          err.message = `请求地址出错: ${err.response.config.url}`
          break

        case 408:
          err.message = '请求超时'
          break

        case 500:
          err.message = '服务器内部错误'
          break

        case 501:
          err.message = '服务未实现'
          break

        case 502:
          err.message = '网关错误'
          break

        case 503:
          err.message = '服务不可用'
          break

        case 504:
          err.message = '网关超时'
          break

        case 505:
          err.message = 'HTTP版本不受支持'
          break

        default:
      }
    }

    if (!err.response) {
      // 断网了
      Message.error({
        message: '系统繁忙，请稍后重试！'
      })
      console.log('接口返回错误error信息', err)
      return Promise.reject(err)
    }
  }
)

export default axios
