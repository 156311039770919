(function (doc, win) {
  let docEl = doc.documentElement
  let resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize'
  let recalc = function () {
    let clientWidth = docEl.clientWidth
    if (!clientWidth) return

    // rem与px相转换的比例系数
    let remUnit = 100
    // UI设计稿宽度
    let designWidth = 2560

    let standardFontSize = clientWidth / (designWidth / remUnit) + 'px'
    // console.log('standardFontSize', standardFontSize)
    docEl.style.fontSize = standardFontSize
  }

  if (!doc.addEventListener) return
  win.addEventListener(resizeEvt, recalc, false)
  doc.addEventListener('DOMContentLoaded', recalc, false)
})(document, window)
